import "./header.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Container,
  Nav,
  Dropdown,
  Image,
  Offcanvas,
  NavLink,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEllipsisV,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { ArrowDown, ArrowUp, ProfilePic } from "../../../../assets/images";
import HeaderLogo from "../../../../assets/svg/headerlogoblack";
import Cookies from "js-cookie";

export const LoggedInHeader = (props) => {
  const [isOpen, setisOpen] = useState(false);
  const [showOffCanvasMenu, setShowOffCanvasMenu] = useState(false);

  const toggleOffCanvasMenu = () => {
    setShowOffCanvasMenu(!showOffCanvasMenu);
  };

  useEffect(() => {
    // let user = JSON.parse(localStorage.getItem('user'));
    // setProfile(user);
    // setNotificationState(notifificationData);
  }, []);
  const ToggleIcon = () => {
    setisOpen(!isOpen);
    // alert("asdasdasdasd");
  };
  const handleLogout = async () => {
    localStorage.clear();
    Cookies.remove("token");
  };

  return (
    <header className="customHeader">
      <Navbar expand="md" sticky="top">
        <Container>
          <Navbar.Brand className="me-0">
            <Link to="/">
              <HeaderLogo />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle className="order-4 order-lg-2 notButton">
            <FontAwesomeIcon
              className="bell-icon "
              icon={faEllipsisV}
              onClick={toggleOffCanvasMenu}
            />
          </Navbar.Toggle>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="customCollapse order-3"
            // style={{ backgroundColor: "palegoldenrod" }}
          >
            <Nav className="ms-auto">
              <Dropdown
                className="userDropdown"
                show={isOpen}
                onToggle={(e) => setisOpen(e)}
                onClick={ToggleIcon}
              >
                <Dropdown.Toggle
                  variant="transparent"
                  className="notButton toggleButton "
                  // style={{ backgroundColor: "pink" }}
                >
                  <div className="userImage">
                    <img src={ProfilePic} alt="" className="img-fluid me-2" />
                  </div>
                  <span className="me-2">
                    {/* {profile.first_name + " " + profile.last_name} */}
                    profile name
                  </span>
                  <div className="userImage">
                    <Image
                      src={isOpen ? ArrowUp : ArrowDown}
                      style={{ width: "20px" }}
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="userMenu" align="end">
                  <Link className="userMenuItem" to={"/admin/my-profile"}>
                    <FontAwesomeIcon
                      className="me-2 yellow-text"
                      icon={faUser}
                    />
                    My Profile
                  </Link>
                  <Link
                    className="userMenuItem"
                    to="/"
                    onClick={() => handleLogout()}
                  >
                    <FontAwesomeIcon
                      className="me-1 yellow-text"
                      icon={faSignOut}
                    />
                    Logout
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Offcanvas
        show={showOffCanvasMenu}
        onHide={toggleOffCanvasMenu}
        className="off-convas-header"
      >
        <Offcanvas.Header closeButton className="position-relative ">
          <Offcanvas.Title>
            <h2>Menudasd</h2>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="mob-menu">
          <Nav className="flex-column">
            <NavLink
              // exact
              activeclassname="active"
              className="me-xl-4 me-lg-3"
              to="/"
            >
              Home
            </NavLink>
            <NavLink
              activeclassname="active"
              className="me-xl-4 me-lg-3"
              to="/about-us"
            >
              About Us
            </NavLink>
            <NavLink
              activeclassname="active"
              className="me-xl-4 me-lg-3"
              to="/featured-ads"
            >
              Featured Ads
            </NavLink>
            <NavLink
              activeclassname="active"
              className="me-xl-4 me-lg-3"
              to="/ads"
            >
              Ads
            </NavLink>
            <NavLink
              activeclassname="active"
              className="me-xl-4 me-lg-3"
              to="/contact-us"
            >
              Contact Us
            </NavLink>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
};
